import React, { useState } from "react";
import { Box, TextField, Button, FormControl } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SEO from "./seo";
import { Link } from "gatsby";
import axios from "axios";
import { v4 } from "uuid";
import { navigate } from "gatsby";
import { grey } from "@material-ui/core/colors";

export default () => {
  const xsMatch = useMediaQuery("(min-width:800px)");
  const typingTimer = React.useRef(null);
  const [isBusy, setBusy] = useState(false);

  const [state, setState] = useState({
    company: "",
    name: "",
    email: "",
    note: "",
    phone: "",
    location: "",
    teamSize: "",
  });

  const enrichData = async (email) => {
    try {
      const ip = await axios.get("https://api.ipify.org");
      const res = await axios.get(
        `https://person.clearbit.com/v2/combined/find?email=${email}&ip_address=${ip?.data}`,
        {
          headers: {
            Authorization: "Bearer sk_7165ca8344857e279029d033a760c739",
          },
        }
      );
      setState((s) => ({
        ...s,
        company: res?.data?.company?.name || "",
        name:
          res?.data?.person?.name?.fullName ||
          res?.data?.person?.name?.givenName ||
          "",
      }));
    } catch (e) {
      console.error(e);
    }
  };

  const handleValueChange = React.useCallback((event) => {
    if (!event || !event.target) return;
    const { name, value } = event.target;
    setState((s) => ({
      ...s,
      [name]: value,
    }));
    if (
      name === "email" &&
      value.indexOf("@") > 0 &&
      value.indexOf(".") > 0 &&
      value.indexOf("gmail") == -1
    ) {
      if (typingTimer.current) clearTimeout(typingTimer.current);
      typingTimer.current = setTimeout(enrichData, 300, value);
    }
  }, []);

  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      setBusy(true);
      axios.put(
        `https://lablog-62234.firebaseio.com/request-feature-xlsx/+${v4()}.json`,
        { data: state }
      );
      navigate("/form-submitted/", state);
    },
    [state]
  );

  return (
    <>
      <SEO
        title="ELN Vendor Assessment Template"
        description="This ELN Vendor Assessment Template allows you to quickly compare ELN features among different software providers."
      />

      <Box>
        <Box
          p={3}
          style={{
            background: "linear-gradient(90deg, #4b6cb7 0%, #182848 100%)",
          }}
        >
          <Box
            color="#FFFFFF"
            fontSize={xsMatch ? "4em" : "2em"}
            fontWeight="550"
            component="h1"
            textAlign="center"
          >
            ELN Vendor Assessment Template
          </Box>
          <Box color="#FFFFFF" fontWeight="350" textAlign="center" mt={1.25}>
            The template helps you compare ELN features offered by different
            software vendors
          </Box>
        </Box>
        <Box display="flex" width="100%" justifyContent="center">
          <Box>
            <Box p={3} maxWidth="850px">
              <Box component="h2">
                What is an ELN Feature Comparison Template?
              </Box>
              <Box component="p">
                The template is an MS Excel file that is customized based on
                your laboratory setting and workflow. Submit the form below and
                we'll send you a template customized for you based on the
                provided information. You'll receive exactly one email from us
                with the template file attached - no commitment and no marketing
                email chains.
              </Box>
              <Box component="h2">
                How is this template different from the ELN Comparison Matrices
                available online?
              </Box>
              <Box component="p">
                We are aware that a number of IT departments at academic
                institutions have published different versions of an ELN
                Comparison Matrix. This matrix compares basic features that are
                required in academic labs. It does not consider lab specific
                workflows and feature requirements. Another important point to
                consider is that the matrix has quickly become outdated as
                software technology has evolved and new ELN offerings have
                become available. If you work in a non-academic lab we highly
                encourage you to work in a cross-functional team to plan out
                your ELN evaluation strategy. The ELN vendor evaluation template
                you receive from us is created by a team of research scientists
                and Information Technology experts.
              </Box>
              <Box component="h2">
                We are looking to evaluate ELN vendors for my large company or
                academic institute. Will this template be useful for us?
              </Box>
              <Box component="p">
                Yes, it could help you with your ELN evaluation process. Please
                use the comments field in the form below to describe your
                particular work environments and we'll modify the template for
                you.
              </Box>
              <Box component="h2">
                Will I receive multiple emails from you if I fill out this form?
              </Box>
              <Box component="p">
                No, you'll receive exactly one email from us with the template
                file attached - no commitment and no marketing email chains.
              </Box>
              <Box component="h2">
                I don't want to fill out any templates, can I just request a
                demo of your product?
              </Box>
              <Box component="p">
                Yes, please <Link to="/request-demo">schedule a demo here</Link>
                .
              </Box>
              <Box component="h2">
                What are some of the other approaches for evaluating Electronic
                Lab Notebook options?
              </Box>
              <Box component="p">
                After narrowing down the list of potential ELN vendors, we
                suggest you schedule a demo to get an overview of the software
                options. You can then either fill out the evaluation template
                for each vendor or request that the vendors complete the
                template for you. After the demo meetings you can then use the
                completed forms to score the different options to further narrow
                down your options. Once you have selected one or two vendors we
                suggest that you request a free one month trial.
              </Box>
              <Box display="flex" justifyContent="center">
                <Box mt={1.5} maxWidth="600px">
                  <Box
                    component="h2"
                    mb={0}
                    textAlign="center"
                    bgcolor={blue[900]}
                    color="#FFFFFF"
                    p={4}
                  >
                    ELN Vendor Evaluation Template Request Form
                  </Box>
                  <Box p={2} bgcolor={grey[50]}>
                    <form onSubmit={handleSubmit}>
                      <Box>
                        <TextField
                          id="email-input"
                          name="email"
                          onChange={handleValueChange}
                          value={state.email}
                          aria-describedby="email-input"
                          label="Email"
                          variant="outlined"
                          fullWidth
                          autoComplete="new-password"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          margin="normal"
                          placeholder="Type your email address here..."
                        />
                      </Box>
                      <Box>
                        <TextField
                          id="name-input"
                          variant="filled"
                          name="name"
                          value={state.name}
                          onChange={(e) => handleValueChange(e)}
                          aria-describedby="name-input"
                          label="Full name"
                          variant="outlined"
                          fullWidth
                          autoComplete="new-password"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          margin="normal"
                          placeholder="Your name"
                        />
                      </Box>
                      <Box>
                        <TextField
                          id="company-input"
                          name="company"
                          value={state.company}
                          onChange={handleValueChange}
                          aria-describedby="company-input"
                          label="Organization"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          autoComplete="new-password"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          placeholder="Company or institute name"
                        />
                      </Box>
                      <Box>
                        <TextField
                          id="teamSize-input"
                          name="teamSize"
                          type="number"
                          value={state.teamSize}
                          required
                          onChange={handleValueChange}
                          aria-describedby="teamSize-input"
                          label="Team size"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          autoComplete="new-password"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          placeholder="Number of users"
                        />
                      </Box>
                      <Box mt={2}>
                        <FormControl
                          variant="filled"
                          fullWidth
                          size="small"
                          margin="dense"
                        >
                          <TextField
                            label="Comments"
                            value={state.note}
                            multiline
                            required
                            rows={6}
                            rowsMax={6}
                            variant="outlined"
                            placeholder="What are you hoping to accomplish with a new Electronic Lab Notebook platform?"
                            InputLabelProps={{ shrink: true }}
                            name="note"
                            onChange={handleValueChange}
                          />
                        </FormControl>
                      </Box>
                      <Box marginTop="30px" pl={2} pr={2}>
                        <Button
                          disabled={
                            isBusy ||
                            !state.email ||
                            state.email.indexOf("@") < 0 ||
                            !state.name ||
                            !state.company ||
                            state.email.indexOf("gmail") > -1
                          }
                          disableElevation
                          variant="contained"
                          type="submit"
                          color="primary"
                          fullWidth
                          style={{ textTransform: "none" }}
                        >
                          Send me the template
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
